import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Axios from "axios";

import Layout from "../components/layout";
import Seo from "../components/seo";

import "../assets/css/index.css";
import useWindowWidth from "../hooks/useWindowWidth";

const IndexPage = () => {
	const [isModalOpen, setIsModalOpen] = useState(0);
	const windowWidth = useWindowWidth();

	function handleClick(value) {
		switch (value) {
			case 1:
				value = "Borovnica";
				break;
			case 2:
				value = "Slik Milk";
				break;
			case 3:
				value = "TopMix";
				break;
		}

		setIsModalOpen(1);

		window != undefined &&
			Axios.post("./../../sladoled-add.php", ({ sladoled: value }))
				.then(function (response) {
					console.log(response);
					window != undefined &&
						localStorage.setItem("UserDidVote", true);
				})
				.catch(function (error) {
					console.log(error);
				});
	}

	if (typeof window === `undefined`) {
	    return(<></>);
	}

	return (
		<Layout>
			<Seo title="Početna" />
			{windowWidth < 600 ? (
				<div>
					<StaticImage
						src="../assets/images/hero_m_one.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						style={{ width: `100%` }}
						objectFit="contain"
					/>
					<StaticImage
						src="../assets/images/hero_m_two.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						style={{ width: `100%` }}
						objectFit="contain"
						className="hero_m_two"
					/>
				</div>
			) : (
				<StaticImage
					src="../assets/images/hero.png"
					quality={100}
					formats={["auto", "webp", "avif"]}
					alt="Hero"
					style={{ width: `100%` }}
					objectFit="contain"
					className="hero_m_one"
				/>
			)}

			<div className="banner">
				<p>Ledo legende se ne zaboravljaju, a mi jednu vraćamo tebi!</p>
			</div>
			<div className="heading">
				<h2>Pogodi o kojoj je legendi riječ.</h2>
			</div>

			<div className="ice_ice_baby">
				<div className="container">
					<StaticImage
						src="../assets/images/Borovnica - PNG.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						style={{ width: `100%` }}
					/>

					<span className="button" onClick={() => handleClick(1)}>
						Glasaj
					</span>
				</div>
				<div className="container">
					<StaticImage
						src="../assets/images/Slik Milk - PNG.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						style={{ width: `100%` }}
					/>

					<span className="button" onClick={() => handleClick(2)}>
						Glasaj
					</span>
				</div>
				<div className="container">
					<StaticImage
						src="../assets/images/3D_TopMix_Box.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						style={{ width: `100%` }}
					/>

					<span className="button" onClick={() => handleClick(3)}>
						Glasaj
					</span>
				</div>
			</div>

			<Link style={{opacity: "0", pointerEvents: "none"}} to="file.pdf" target="_blank" className="rules">
				<span>PREUZMI</span>
				<div className="link">
					<p>Pravila natječaja 2022</p>
					<StaticImage
						className="img"
						src="../assets/images/group.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						objectFit="contain"
					/>
				</div>
			</Link>

			<div className={`modal${isModalOpen ? " open" : ""}`}>
				<div
					className="overlay"
					onClick={() => setIsModalOpen(0)}
				></div>
				<div className="modal_container">
					<StaticImage
						src="../assets/images/x.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						objectFit="contain"
						className="close"
						onClick={() => setIsModalOpen(0)}
					/>
					<StaticImage
						src="../assets/images/hero_m_two.png"
						quality={100}
						formats={["auto", "webp", "avif"]}
						alt="Hero"
						objectFit="contain"
						className="image_modal"
					/>
					<h2>Ti si naša legenda!</h2>
					<p>
						Hvala ti na predviđanju koju legendu ćemo vratiti u Ledo
						škrinje. Prati i dalje naše stranice i društvene mreže
						na kojima ćemo uskoro otkriti koju Ledo legendu vraćamo!
					</p>
					<div className="social">
						<Link to="https://www.instagram.com/ledohrvatska" className="icon">
							<StaticImage
								src="../assets/images/ig.png"
								quality={100}
								formats={["auto", "webp", "avif"]}
								alt="instagram"
								objectFit="contain"
								className="social_icon"
							/>
						</Link>
						<Link to="https://www.facebook.com/ledo.medo" className="icon">
							<StaticImage
								src="../assets/images/fb.png"
								quality={100}
								formats={["auto", "webp", "avif"]}
								alt="facebook"
								objectFit="contain"
								className="social_icon"
							/>
						</Link>
					</div>
					<span className="button" onClick={() => setIsModalOpen(0)}>
						Povratak
					</span>
				</div>
			</div>
		</Layout>
	);
};
export default IndexPage;
